.fit-content {
  max-width: 1200px;
  margin: 0 auto;
}

.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-space-around {
  justify-content: space-around;
}

.align-center {
  align-items: center;
}

.text-center {
  text-align: center;
}

.flex-col {
  flex-direction: column;
}
