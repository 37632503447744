.footer {
  background-color: var(--blue8);
  color: var(--grey1);
  font-size: 14px;

  .footer-top {
    padding: 24px 0px;
  }

  .footer-bottom {
    padding: 24px;
    text-align: center;
    border-top: 1px solid var(--grey1);
  }
}

.footer-title {
  font-size: 20px;
  font-weight: 600;
  color: var(--white);
  position: relative;
  margin-bottom: 50px;
  @include maxWidth(768) {
    margin-bottom: 30px;
  }
  &::after {
    content: "";
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 20%;
    height: 2px;
    background: var(--white);
    @include maxWidth(768) {
      bottom: -10px;
    }
  }
}

.footer-top {
  display: flex;
  @include maxWidth(768) {
    display: block;
  }
  > div {
    width: 33.3%;
    @include maxWidth(768) {
      width: 100%;
      padding: 0px 40px;
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .footer-icon {
    font-size: 18px;
    margin-right: 10px;
  }
}

.footer-contact-info {
  .contact-item {
    margin-bottom: 16px;
    @include maxWidth(768) {
      margin-bottom: 10px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  address {
    font-style: normal;
    p {
      margin-bottom: 4px;
    }
  }
}

.footer-navigation-links {
  li {
    cursor: pointer;
    margin-bottom: 16px;
    @include maxWidth(768) {
      margin-bottom: 10px;
    }
  }
}

.footer-social {
  .social-icon {
    margin-right: 18px;
    display: block;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      color: var(--blue6);
    }
  }
}
