html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  color: var(--grey6);
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  max-width: 100%;
}
