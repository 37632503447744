@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins-ExtraBold.eot");
  src: url("../assets/fonts/Poppins-ExtraBold.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/Poppins-ExtraBold.woff2") format("woff2"),
    url("../assets/fonts/Poppins-ExtraBold.woff") format("woff"),
    url("../assets/fonts/Poppins-ExtraBold.svg#Poppins-ExtraBold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins-Bold.eot");
  src: url("../assets/fonts/Poppins-Bold.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/Poppins-Bold.woff2") format("woff2"), url("../assets/fonts/Poppins-Bold.woff") format("woff"),
    url("../assets/fonts/Poppins-Bold.svg#Poppins-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins-Black.eot");
  src: url("../assets/fonts/Poppins-Black.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/Poppins-Black.woff2") format("woff2"), url("../assets/fonts/Poppins-Black.woff") format("woff"),
    url("../assets/fonts/Poppins-Black.svg#Poppins-Black") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins-ExtraLight.eot");
  src: url("../assets/fonts/Poppins-ExtraLight.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/Poppins-ExtraLight.woff2") format("woff2"),
    url("../assets/fonts/Poppins-ExtraLight.woff") format("woff"),
    url("../assets/fonts/Poppins-ExtraLight.svg#Poppins-ExtraLight") format("svg");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins-Medium.eot");
  src: url("../assets/fonts/Poppins-Medium.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/Poppins-Medium.woff2") format("woff2"),
    url("../assets/fonts/Poppins-Medium.woff") format("woff"),
    url("../assets/fonts/Poppins-Medium.svg#Poppins-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins-Light.eot");
  src: url("../assets/fonts/Poppins-Light.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/Poppins-Light.woff2") format("woff2"), url("../assets/fonts/Poppins-Light.woff") format("woff"),
    url("../assets/fonts/Poppins-Light.svg#Poppins-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins-SemiBold.eot");
  src: url("../assets/fonts/Poppins-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/Poppins-SemiBold.woff2") format("woff2"),
    url("../assets/fonts/Poppins-SemiBold.woff") format("woff"),
    url("../assets/fonts/Poppins-SemiBold.svg#Poppins-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins-Thin.eot");
  src: url("../assets/fonts/Poppins-Thin.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/Poppins-Thin.woff2") format("woff2"), url("../assets/fonts/Poppins-Thin.woff") format("woff"),
    url("../assets/fonts/Poppins-Thin.svg#Poppins-Thin") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins-Regular.eot");
  src: url("../assets/fonts/Poppins-Regular.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/Poppins-Regular.woff2") format("woff2"),
    url("../assets/fonts/Poppins-Regular.woff") format("woff"),
    url("../assets/fonts/Poppins-Regular.svg#Poppins-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
