.header {
  padding: 20px 0;
  box-shadow: 16px 16px 35px rgba(0, 0, 0, 0.09);
  @include maxWidth(768) {
    padding: 16px;
  }
  .header-logo {
    max-width: 100px;
    cursor: pointer;
    display: block;

    @include maxWidth(768) {
      max-width: 70px;
    }
  }
}

.menu-icon {
  display: none;
  @include maxWidth(768) {
    display: block;
  }
}

.header-nav-links {
  &.open {
    @include maxWidth(768) {
      position: fixed;
      overflow: scroll;
      background: var(--white);
      width: 100%;
      height: 100%;
      left: 0;
      z-index: 1;
      top: 0;
    }
  }
  .web-links {
    display: flex;
    @include maxWidth(768) {
      display: none;
    }
  }
  .close-icon {
    justify-content: flex-end;
    padding: 20px 10px;
    display: none;
    font-size: 30px;
    @include maxWidth(768) {
      display: flex;
    }
  }
  ul {
    @include maxWidth(768) {
      display: block;
    }
  }
  li {
    display: block;
    margin-right: 30px;
    font-size: 16px;
    font-weight: 500;
    position: relative;
    font-weight: 600;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
    &:hover::after {
      content: "";
      position: absolute;
      bottom: -4px;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: var(--blue8);
    }
    @include maxWidth(768) {
      padding: 10px;
      border-bottom: 1px solid var(--blue4);
      width: 100%;
    }
  }
}
