.page-careers {
  &__body {
    padding: 40px 0;
    @include maxWidth(768) {
      padding: 20px 10px;
    }
  }
  .hr {
    margin: 20px 0;
    border-top: 1px solid #eee;
  }
  .technology-item {
    display: flex;
    background-color: #e1e9f9;
    padding: 20px 10px;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 2px 3px #ccc;

    @include maxWidth(768) {
      flex-direction: column-reverse;
    }

    &.left {
      flex-direction: row-reverse;
      @include maxWidth(768) {
        flex-direction: column-reverse;
      }
    }

    h4 {
      font-size: 26px;
      margin-bottom: 20px;
      font-weight: 500;
      @include maxWidth(768) {
        font-size: 22px;
        margin-bottom: 10px;
      }
    }

    .content {
      flex: 1;
      padding: 0 16px;
      font-size: 14px;
      @include maxWidth(768) {
        padding: 0;
      }
      b {
        font-weight: 500;
      }

      p {
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }

      ul {
        list-style: disc;
        padding-left: 30px;
      }
    }

    .image {
      width: 40%;
      padding: 0 16px;
      @include maxWidth(768) {
        width: 100%;
        padding: 0;
      }
      img {
        width: 100%;
      }
    }
  }
}
