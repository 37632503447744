.page-careers {
  .content {
    padding: 40px 0;
    @include maxWidth(768) {
      padding: 20px 10px;
    }
    .title {
      font-size: 38px;
      font-weight: bold;
      color: var(--red2);
    }

    .description {
      font-size: 14px;
      color: var(--blue6);
      margin-bottom: 20px;
      line-height: 22px;
      color: var(--grey6);
    }
  }
}

.career-item {
}
