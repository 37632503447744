.page-banner {
  background-image: url("../../assets/images/careers.jpg");
  background-repeat: no-repeat;
  background-position: center top;

  .page-banner-content {
    padding: 180px 0;
    text-align: center;
    color: var(--white);

    @include maxWidth(768) {
      padding: 110px 0;
    }

    h1 {
      font-size: 40px;
      display: inline-block;
      position: relative;

      @include maxWidth(768) {
        font-size: 28px;
      }

      &::after {
        content: "";
        position: absolute;
        bottom: -4px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: var(--white);
      }
    }
  }
}
