.services-menu-list {
  li {
    border: 1px solid var(--blue4);
    border-radius: 2px;
    margin-bottom: 14px;
    font-weight: 500;
    cursor: pointer;
    a {
      padding: 6px;
      align-items: center;
      justify-content: space-between;
      display: flex;
    }
    &.active {
      color: var(--blue6);
      border-color: var(--blue6);
    }
  }
}
