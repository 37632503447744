.services-page {
  padding: 40px 0;

  @include maxWidth(768) {
    display: block;
  }

  .services-nav {
    position: sticky;
    width: 340px;
    top: 60px;
    display: inline-block;
    vertical-align: top;

    @include maxWidth(768) {
      position: static;
      width: 100%;
      padding: 10px;
    }
  }

  .services-content {
    display: inline-block;
    width: calc(100% - 350px);
    padding: 0px 40px;
    font-size: 14px;
    @include maxWidth(768) {
      width: 100%;
      padding: 20px 10px;
    }
    img {
      width: 100%;
      height: 300px;
    }
    h2 {
      font-size: 40px;
      font-weight: 500;
      margin: 4px 0;
    }
    p {
      margin: 10px 0;
    }

    h2 {
      font-size: 24px;
      font-weight: 500;
    }

    h3 {
      font-size: 20px;
      font-weight: 500;
    }

    h4 {
      font-weight: 600;
      font-size: 16px;
    }

    ul {
      list-style: disc;
      padding-left: 40px;
      margin: 6px 0;
    }
  }
}
