.page-clients {
  &__body {
    padding: 40px 0;
    @include maxWidth(768) {
      padding: 20px 0;
    }
  }
  .section-title {
    text-align: center;
    padding: 0 60px;
    @include maxWidth(768) {
      margin-bottom: 20px;
    }
  }
  .page-content {
    font-size: 14px;
    text-align: center;
  }

  .client-items {
    margin-top: 30px;
    @include maxWidth(768) {
      display: block;
      margin-top: 20px;
    }
    .client-item {
      width: 25%;
      padding-right: 15px;
      padding-left: 15px;
      margin-bottom: 20px;
      @include maxWidth(768) {
        width: 100%;
      }
    }

    .single-logo {
      border: 1px solid #1b97d4;
      text-align: center;
      border-radius: 4px;
      margin-bottom: 10px;
      img {
        height: 110px;
        object-fit: contain;
        width: auto;
        opacity: 1;
        -webkit-transition: all 0.5s;
        transition: all 0.5s;
        &:hover {
          opacity: 0.4;
        }
      }
    }
  }
}
