.page-aboutus {
  padding-top: 40px;
  @include maxWidth(768) {
    padding-top: 16px;
  }

  .banner-image {
    filter: grayscale(70%);
    max-width: 500px;
  }

  .title {
    font-size: 38px;
    font-weight: bold;
    color: var(--red2);

    @include maxWidth(768) {
      font-size: 24px;
      line-height: 28px;
    }
  }
}

.content-wrapper {
  @include maxWidth(768) {
    display: block;
  }
  .content {
    flex: 1;
    padding: 30px 50px 0;

    @include maxWidth(768) {
      padding: 16px;
    }

    .tagline {
      color: var(--blue5);
      margin-top: 0;
      font-size: 30px;
      font-weight: 700;
      padding-top: 5px;
      line-height: 1.375;
      margin-bottom: 30px;

      @include maxWidth(768) {
        font-size: 20px;
        margin-bottom: 10px;
      }
    }

    p {
      font-size: 18px;
      color: var(--blue6);
      margin-bottom: 20px;
      line-height: 22px;
      color: var(--grey6);

      @include maxWidth(768) {
        font-size: 14px;
      }
    }
  }
}

.working-process {
  background-image: url("../../assets/images/services.jpeg");
  background-size: cover;
  padding: 40px 0px;

  @include maxWidth(768) {
    padding: 24px 0 16px 0;
  }

  .title {
    font-size: 45px;

    @include maxWidth(768) {
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 6px;
    }
  }

  .description {
    font-size: 18px;
    max-width: 800px;
    margin: 0 auto;
    margin-bottom: 50px;
    text-align: center;

    @include maxWidth(768) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .cards {
    padding-top: 80px;
    flex-wrap: wrap;
    justify-content: center;
    gap: 100px;
    @include maxWidth(768) {
      display: block;
      padding-top: 40px;
    }
  }

  .card-wrapper {
    @include maxWidth(768) {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 60px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .process-card {
    width: 300px;
    height: 210px;
    padding: 0 15px;
    position: relative;
    &:last-child {
      margin-bottom: 0;
    }

    @include maxWidth(768) {
      width: 100%;
    }

    &__image_wrapper {
      position: absolute;
      width: 80px;
      height: 85px;
      border: 1px solid var(--blue5);
      z-index: 1;
      transition: 500ms all ease;
      border-radius: 13px;
      background-color: var(--white);
      font-size: 30px;
      top: -42px;
      left: 50%;
      transform: translateX(-50%);
      &:hover {
        background-color: rgba(#0858c1, 0.5);
        color: var(--white);
      }
    }

    .card-content {
      background-color: var(--white);
      padding: 70px 15px 30px;
      height: 100%;
      border: 1px solid var(--blue5);
      border-radius: 12px;
      overflow: hidden;
      position: relative;

      @include maxWidth(768) {
        height: 200px;
        padding: 60px 8px 20px;
      }
    }

    h3 {
      font-size: 24px;
      font-weight: 700;
      color: var(--blue7);
      padding-bottom: 12px;
    }
    p {
      font-size: 14px;
      line-height: 20px;
      color: var(--grey6);
    }
  }
}

.our_clients {
  padding: 40px 0;
  background-image: url("../../assets/images/services.jpeg");
  background-size: cover;

  @include maxWidth(768) {
    padding: 20px 0;
  }
  .section-title {
    text-align: center;
    padding: 0 60px;
    margin-bottom: 35px;

    @include maxWidth(768) {
      margin-bottom: 20px;
    }
  }

  .client-items {
    @include maxWidth(768) {
      display: block;
    }
  }

  .client-item {
    width: 25%;
    padding-right: 15px;
    padding-left: 15px;
    margin-bottom: 20px;
    @include maxWidth(768) {
      width: 100%;
    }
  }

  .single-logo {
    border: 1px solid #1b97d4;
    background-color: var(--white);
    text-align: center;
    border-radius: 4px;
    margin-bottom: 10px;
    img {
      height: 110px;
      object-fit: contain;
      width: auto;
      opacity: 1;
      -webkit-transition: all 0.5s;
      transition: all 0.5s;
      &:hover {
        opacity: 0.4;
      }
    }
  }
}

.services-offered {
  padding: 40px 0;

  @include maxWidth(768) {
    padding: 20px 10px;
  }

  .services-offered-cards {
    flex-wrap: wrap;
    gap: 50px;
    margin-top: 50px;
  }

  .services-offered-card {
    width: 25%;
    position: relative;
    overflow: hidden;
    border: 2px solid var(--blue6);
    border-radius: 6px;
    overflow: hidden;

    @include maxWidth(768) {
      width: 100%;
    }
    img {
      transform: scale(1.2);
      transition: transform 0.3s ease;
      height: 170px;
      width: 100%;
      @include maxWidth(768) {
        height: auto;
      }
    }
    &:hover {
      img {
        transform: scale(1.1);
      }

      .overlay {
        width: 80%;
        height: 80%;
        opacity: 1;
      }
    }

    .overlay {
      width: 60%;
      height: 60%;
      position: absolute;
      top: 50%;
      left: 50%;
      opacity: 0;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(39, 130, 249, 0.9);
      border-radius: 6px;
      transition: all 0.3s ease-in;
      color: var(--white);
      font-size: 18px;
      font-weight: 600;
      cursor: pointer;

      @include maxWidth(768) {
        font-size: 24px;
      }
    }
  }
}
